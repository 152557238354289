
const TARGET_URL = 'https://apply-dev.spartanjob.ch';
//  const TARGET_URL = 'http://localhost:4300';


const API_URL = '/api';

const PORT = 4000;
export const environment = {
  production: true,
  env:'prod',
  ENDPOINTS: {
    BASE_URL: '',
    LOGIN_URL: `${API_URL}/authenticate`,
    REGISTER_URL: `${API_URL}/register`,
    CHANGE_PASSWORD_URL : `${API_URL}/account/change-password`,
    FORGOT_PASSWORD_URL: `${API_URL}/account/reset-password/init`,
    RESET_PASSWORD_URL: `${API_URL}/account/reset-password/finish`,
    PUBLIC_ANNOUNCEMENTS: `${API_URL}/public/job-offers`,
    CANDIDATE_REGISTER : `${API_URL}/candidate/register`,
    COUNTRIES : `${API_URL}/countries`,
    CANDIDATE_CREATE_URL : `${API_URL}/create-candidate`,
    CANDIDATES_URL : `${API_URL}/candidates`,
    ACCOUNT_ACTIVATION:`${API_URL}/activate`,
    EXTRACT_RESUME : `${API_URL}/extract-resume`,
    PUBLIC_COMPANIES_URL : `${API_URL}/public/client-accounts`,
    PUBLIC_SEARCH_URL: `${API_URL}/public/job-offers/search`,
    PROFILE_INFOS_URL : `${API_URL}/candidates/mine`,
    PHONE_COUNTRIES_URL : `assets/countries/countries.json`,
    CLIENTS_URL: `${API_URL}/client-accounts`,
    ANNOUNCEMENTS_URL: `${API_URL}/job-offers`,
    ANNOUNCEMENTS_URL_WITH_SUB_STATUS: `${API_URL}/job-listings`,
    CANDIDACIES_URL: `${API_URL}/candidacies`,
    SKILLS_AI_URL : `${API_URL}/chat/skills`,
    UPDATE_RESUME_URL : `${API_URL}/candidates/resume`,
    LOGO_AMAZON_URL : `https://s3.pub1.infomaniak.cloud/object/v1/AUTH_544c549bf3784a2f9b5cc3641a070fd9/sj-prod-public/client`,
    SPARTAN_URL : `https://portal.spartanjob.ch/`,
    CGU_URL : `${API_URL}/policy/`,
    PPD_URL : `${API_URL}/policy/`,
    POLICIES_URL : `${API_URL}/policies`,
    ALL_POLICIES_URL : `${API_URL}/policies/all/`,
    ALERT_URL : `${API_URL}/job-offer-alerts`,
    QUICK_APPLY: `${API_URL}/public/job-offers/`
  }
};
